import React from "react";
import Layout from "../../components/Layout";

export default function proyectosEditoriales() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/3.jpg)`}}
                    >
                        <h1>Proyectos Editoriales</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción:</h1>
                        <p className="services_section_1__paragraph">
                            Desarrollamos proyectos a medida acompañando todo el proceso editorial desde la idea inicial
                            hasta la distribución, contamos con un equipo de expertos en gestión editorial que
                            desarrollan diversas áreas del conocimiento desde una obra literaria hasta un texto de
                            investigación científica y académica, adicionalmente le acompañamos en los procesos de:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Redacción</li>
                            <li>Ilustración</li>
                            <li>Diseño</li>
                            <li>Maquetación</li>
                            <li>Edición Electrónica</li>
                            <li>Impresión</li>
                            <li>Registro de ISBN</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}